<template lang="pug">
div
  //- Preparation
  .row.container_meet(
    v-show="stage === 0"
    :style="{ height: UsableHeight, width: meetType === 'agent' ? '500px' : '100vw', margin: meetType === 'agent' ? '0px auto' : '' }"
  )
    .col-12.p-0.header.p-1.position-relative
      img.d-block.h-100.mx-auto(@click="debugModeCounter" :src="Logo" v-openlog)
      div(class="version-position") {{ version }} R
    .col-12.p-0
      .imageConnection
        img.size_icon.mx-auto(src="@/assets/conected.gif")
        h5(style="text-align: center;") Estamos conectandote. Por favor espera.

  //- Ready
  .row.container_meet(
    v-show="stage === 1"
    :style="{ height: UsableHeight, width: meetType === 'agent' ? '500px' : '100vw', margin: meetType === 'agent' ? '0px auto' : '' }"
  )
    .col-12.p-0.header.p-1.position-relative
      img.d-block.h-100.mx-auto(@click="debugModeCounter" :src="Logo" v-openlog)
      div(class="version-position") {{ version }}
      transition(name="fade")
        .videoStopSuggestionMessage.text-danger(v-if="videoStopSuggestion") Por problemas de red, hemos desactivado tu cámara. 
    .col-12.p-0.videozone
      VideoCall(
        :audioId="audioId",
        :videosIds="videosIds"
        @CamChange="selectedDeviceInput('VideoInput')"
      )
    div(style=`
      text-align: center;
      max-width: 940px;
      width: 100%;
      justify-content: center;
      margin: auto;
      color: #6C6B74;
      display: flex;
      align-items: center;
      margin-top: 5px;
      `)
      div(:class="attendees.length >= 2 ? 'green-circle' : 'red-circle'")
      small {{ attendees.length >= 2 ? 'Activo ahora' : 'Inactivo ahora' }}
    .col-12.p-0.chatzone
      .chatWrapper(v-show="allowChat")
        div(class="containerMsg")
          div(class="position-relative fullChat")
            .dateTittle
              span {{ timeDay }}
            .messagesZone.p-2(id="full_messages")
              .d-flex.mb-2(
                v-for="(msg, i) in messages" :key="`message_${i}`"
                :id="`message_${i}`"
                :class="{'justify-content-start': !msg.isSelf, 'justify-content-end': msg.isSelf }"
                class="position-relative"
                style="max-width: 55%; min-width: 162px;"
              )
                .msg_cotainer
                  div(:class="{ 'triangulo': msg.isSelf, 'triangulo_noself': !msg.isSelf }")
                  div(class="msg_owner" :class="{ 'msg_owner_client': msg.isSelf, 'msg_owner_agent': !msg.isSelf }")
                    p.pharragraph_message {{ msg.attendeeName }}
                    span(style ="margin-left: 5px;" :class="{ 'msg_time': !msg.isSelf, 'msg_time_send': msg.isSelf }") {{ timeFormat(msg.timestamp) }}
                  div(class="msg_text" :style="{ backgroundColor:  msg.isSelf ? '#212624' : '#54678F', color: '#E9EBF2' }" v-html="msg.text") 
              .typing(v-if="typing")
                .triangulo-chat
                .lds-ellipsis
                  .into-ellipsis
                  .into-ellipsis
                  .into-ellipsis
                  .into-ellipsis
              .typing-2(v-if="typing")
                .triangulo_noself-chat
                .lds-ellipsis
                  .into-ellipsis
                  .into-ellipsis
                  .into-ellipsis
                  .into-ellipsis
            .input-group.p-2(v-show="allowChat")
              input(
                type="file"
                accept="*/*"
                style="display: none"
                ref="file"
                @change="fileHandlerGeneral"
              )
              input(
                type="file"
                accept="image/*"
                style="display: none"
                ref="image"
                @change="fileHandlerImage"
              )
              .input-group-append
                span.input-group-text.attach_btn.py-0.px-2.d-flex.justify-content-center.align-items-center.attachFileToChat(
                  @click="simulateAttach"
                  :style="{ borderRadius: '5px 0px 0px 5px' }"
                )
                  Attach(size="25" class="d-block")
                span.input-group-text.attach_btn.py-0.px-2.d-flex.justify-content-center.align-items-center.attachImageToChat(
                  @click="simulateImage"
                )
                  Camera(size="25" class="d-block")
              input.form-control.type_msg(placeholder="Ingrese su mensaje..." v-model="message" @change="handleChangeMessage" @keyup.enter="messageHandler")
              .input-group-append(@click="messageHandler")
                span.input-group-text.send_btn.py-0.px-2.d-flex.justify-content-center.align-items-center
                  SendOutline(size="20" class="d-block" v-if="!showSendIcon")
                  Send(size="20" class="d-block" v-if="showSendIcon")
      .bottomzone
        .container-fluid
          .row
            .col.text-center(
              style="height: 50px;"
              v-if="meetType === 'agent'"
              @click="toogleSharingEnabled"
              :class="{ 'desktopSharingArea isShared': isSharedVideo, 'desktopSharingArea isNotShared': !isSharedVideo }"
            )
              span(:style="{ color: isSharedVideo ? '#20a464' : '#DC5759' }")
                MonitorShare(class="size-buttons" size="100%")
            .col.text-center(
              class="listStatusDialogShower"
              style="height: 50px;"
              @click="$bvModal.show('List-status')" v-if="debugButton"
            )
              span(style="color: #DC5759")
                ListStatus(class="size-buttons" size="100%")
            .col.text-center(
              :class="{ 'speakerArea isShowed': speakerEnabled, 'speakerArea isHiden': !speakerEnabled }"
              style="height: 50px;"
              @click="toogleSpeakerEnabled()" v-if="false"
            )
              span(style="color: #DC5759")
                img(:src="speakerEnabled ? VolumeHigh : VolumeHighOff" class="size-buttons" size="100%")
            .col.text-center(
              :class="{ 'micArea isShowed': audioEnabled, 'micArea isHiden': !audioEnabled }"
              style="height: 50px;"
              @click="toogleAudioEnabled()"
            )
              span(style="color: #DC5759")
                img(:src="audioEnabled ? Mic : MicOff" class="size-buttons" size="100%")
            .col.text-center(
              :class="{ 'camArea isShowed': camEnabled, 'camArea isHiden': !camEnabled }"
              style="height: 50px;"
              @click="startCamFromBasis"
            )
              span(style="color: #DC5759")
                img(:src="camEnabled ? VideoCam : VideoCamOff" class="size-buttons" size="100%")
            .col.text-center(
              class="cancelMeetDialog"
              style="height: 50px;"
              @click="$bvModal.show('Finalize-call')"
            )
              span(style="color: #DC5759")
                img(:src="PhoneOff" class="size-buttons" size="100%")
  //- Preparation
  .row.container_meet(
    v-show="stage !== 0 && stage !== 1"
    :style="{ height: UsableHeight, width: meetType === 'agent' ? '500px' : '100vw', margin: meetType === 'agent' ? '0px auto' : '' }"
  )
    .col-12.p-0.header.p-1.position-relative
      img.d-block.h-100.mx-auto(@click="debugModeCounter" :src="Logo" v-openlog)
      div(class="version-position") {{ version }}
    .col-12.p-0 
      h5(style="text-align: center; color: firebrick;") No fue posible establecer la videollamada. La solicitud fue rechazada.

  //- Modals
  b-modal(id="Agent-not-connect" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
    div(class="p-2 position-relative w-auto h-auto")
      div(class="d-block text-center")
        p(class="my-4 font-weight-bold text-danger") Lo sentimos
        p(class="my-4 text-danger") No fue posible conectarte con {{ meetType != 'agent' ? 'un' : 'cliente' }}
        p(class="my-4 text-danger") {{ meetType != 'agent' ? 'cliente' : 'asesor' }} en este momento
        p(class="my-4") ¿Qué deseas hacer?
      div(class="position-relative")           
        b-button(@click="$router.push({ path: '/turno' })" variant="outline-danger" class="btn-block ") Reintentar conectar
        b-button(@click="end" variant="danger" class="btn-block") Cancelar solicitud

  b-modal(id="Finalize-call" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer)
    div(class="p-2 position-relative w-auto h-auto")
      div(class="d-block text-center" )
        div(class="my-4")
          p(class="m-0 font-weight-bold text-danger") Estás a punto de finalizar la
          p(class="m-0 font-weight-bold text-danger") videollamada
        //- p(class="m-0 text-danger") Esto cancelará el proceso de atención
        //- p(class="m-0 text-danger") con el {{ meetType != 'agent' ? 'asesor Colmédica' : 'cliente' }}
        p(class="my-5 color-colmedica") ¿Qué deseas hacer?
      div(class="position-relative")
        b-button(@click="$bvModal.hide('Finalize-call')" variant="outline-bluecolmedica" class="btn-block") Volver a la videollamada
        b-button(@click="end" variant="danger" class="btn-block cancelMeetConfirmation") Finalizar la videollamada
  
  b-modal(id="Input-selector" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
    div(class="p-2 position-relative w-auto h-auto")
      div(class="d-block text-center")
        div(
          class="toggle my-2"
          v-show="deviceSelector != 'VideoInput' "
        )
          img(id="deviceOff" class="icon-toogle" :src="deviceSelector == 'AudioInput' ? MicOff : VolumeHighOff")
          toggle-button(
            @change="disableDevice(true)"
            :value="toogleDevice"
            :width="140"
            :height="30"
            color="#82C7EB"              
            :labels="false"
            class="my-0 mx-2"
          )
          img(id="deviceOn"  class="icon-toogle" :src="deviceSelector == 'AudioInput' ? Mic : VolumeHigh")
        template(v-if="toogleDevice" v-for="device in selectedDeviceAllowed")
          b-button(
            v-if="device && device.deviceId"
            :key="device.deviceId"
            @click="changeDeviceSelected(device)"
            block
            :variant="toogleDevice && deviceCurrent && deviceCurrent.deviceId == device.deviceId ? 'bluecolmedica' : 'outline-bluecolmedica'"
            class="my-2 fontSizeButton"
          ) {{ device.label }}

  b-modal(id="List-status" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer :static="true")
    ModalListStatus(class="p-2 position-relative w-auto h-auto")

  b-modal(id="Allowed-video" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer)
    h5 Por favor activa tu cámara.
    .buttons.mt-4
      b-button(@click="startCameraHandler" class="camDialogActivate") Activar
      b-button.ml-2(@click="toogleCamModal" class="camDialogDeactivate") Desactivar
  
  b-modal(
    id="invalid-mime"
    footerClass='p-2 border-top-0'
    headerClass='p-2 border-bottom-0'
    centered=true
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
  )
    h6 No puedes cargar este tipo de archivo. Soportamos imágenes o documentos PDF y Word.
    .buttons.mt-4.mimeErrorCloser
      b-button.ml-2(@click="toogleInvalidModal") Cerrar

  b-modal(
    id="invalid-size"
    footerClass='p-2 border-top-0'
    headerClass='p-2 border-bottom-0'
    centered=true
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
  )
    h6 No puedes cargar este archivo. El tamaño máximo del archivo es de 10 MB.
    .buttons.mt-4.sizeErrorCloser
      b-button.ml-2(@click="toogleInvalidModal") Cerrar

</template>

<script>
import VideoCall from "./components/VideoCall";
import Mobile from "../utils/evalmobile";
import { mapActions, mapMutations, mapState } from "vuex";
import _get from "lodash/get";
import Attach from "mdi-vue/Attachment.vue";
import Send from "mdi-vue/Send.vue";
import SendOutline from "mdi-vue/SendOutline";
import MonitorShare from "mdi-vue/MonitorShare.vue";
import ListStatus from "mdi-vue/ListStatus.vue";
import ModalListStatus from "./components/ModalListStatus";
import Camera from "mdi-vue/Camera.vue";
import moment from "moment";
// import translate from "translate";
import EvalMobile from "@/utils/evalmobile";
// import kuid from "kuid";

// assets
import LogoColmedica from "@/assets/colmedica_logo.png";
import Mic from "@/assets/Mic.png";
import MicOff from "@/assets/MicOff.png";
import VideoCam from "@/assets/VideoCam.png";
import VideoCamOff from "@/assets/VideoCamOff.png";
import VolumeHigh from "@/assets/VolumeHigh.png";
import VolumeHighOff from "@/assets/VolumeHighOff.png";
import PhoneOff from "@/assets/PhoneOff.png";

export default {
  name: "MeetingDirect",

  props: ["meetType", "meetId", "username"],

  components: {
    VideoCall,
    Attach,
    Send,
    SendOutline,
    VolumeHigh,
    Mic,
    VideoCam,
    MonitorShare,
    ListStatus,
    ModalListStatus,
    Camera
  },

  data: () => ({
    stage: 0,
    typing: false,
    debugMode: false,
    timerDebugMode: false,
    debugCounter: 0,
    isiOS: Mobile.iOS(),
    message: "",
    timerWatcher: null,
    allowChat: true,
    agentcharged: false,
    deviceSelector: null,
    LogoColmedica,
    // icons
    PhoneOff: PhoneOff,
    Mic: Mic,
    MicOff: MicOff,
    VideoCam: VideoCam,
    VideoCamOff: VideoCamOff,
    VolumeHigh: VolumeHigh,
    VolumeHighOff: VolumeHighOff,
    validMimeTypes: [
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/octet-stream",
      "image/",
      // "video/",
      // "audio/",
      "application/pdf"
    ],
    translateFormat: null,
    showSendIcon: false
  }),

  created() {
    this.startup();
  },

  watch: {
    attendees(val) {
      if (val.length >= 2) {
        this.allowChat = true;
        this.agentController(true);
      }
    },
    meetingTitle(val, oldVal) {
      if (!val && oldVal) this.$emit("endMeet");
    },

    messages(val) {
      this.$nextTick(() => {
        this.modeScroll(val.length);
      });
    },

    m_allow_video(val) {
      if (val) {
        this.$bvModal.show("Allowed-video");
      } else {
        this.$bvModal.hide("Allowed-video");
      }
    }
  },

  computed: {
    ...mapState({
      version: state => state.version,
      meeting: state => state.meeting.meeting,
      retrieving: state => state.meeting.connecting,
      audioId: state => state.meeting.audioId,
      videosIds: state => state.meeting.videosIds,
      attendees: state => state.meeting.attendees,
      videoInputDevices: state => state.meeting.videoInputDevices,
      audioOutputDevices: state => state.meeting.audioOutputDevices,
      audioInputDevices: state => state.meeting.audioInputDevices,
      // devices
      speakerDevice: state => state.meeting.speakerDevice,
      videoDevice: state => state.meeting.videoDevice,
      audioDevice: state => state.meeting.audioDevice,
      // toggles
      audioEnabled: state => state.meeting.audioEnabled,
      camEnabled: state => state.meeting.camEnabled,
      speakerEnabled: state => state.meeting.speakerEnabled,
      videoStopSuggestion: state => state.meeting.videoStopSuggestion,

      messages: state => state.meeting.messages,
      sharingEnabled: state => state.meeting.sharingEnabled,

      waiting_time: state => state.max_waiting_time,
      turn: state => state.turn,
      m_allow_video: state => state.meeting.m_allow_video,
      env: state => state.env,
      c_data: state => state.client_data,
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    meetIdentity() {
      return this.$route.params.meetId || this.meetId;
    },

    attendanceName() {
      return (
        _get(this.$route, "query.username") || this.username || "Sin nombre"
      );
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    },

    debugButton() {
      return (
        this.env.VUE_APP_DEBUG_BUTTON === "true" ||
        this.meetType === "agent" ||
        this.debugMode
      );
    },

    meetingTitle() {
      return _get(this.meeting, "Meeting.Meeting.ExternalMeetingId");
    },

    meetingUrl() {
      return `${location.origin}/#/meeting/${this.meetingTitle}`;
    },

    assignerURL() {
      return `${location.origin}/#/turn_assigner/${this.meetingTitle}`;
    },

    selectedDeviceAllowed() {
      if (this.deviceSelector == "VideoInput") return this.videoInputDevices;
      if (this.deviceSelector == "AudioInput") return this.audioInputDevices;
      if (this.deviceSelector == "AudioOutput") return this.audioOutputDevices;
      return null;
    },

    deviceCurrent() {
      if (this.deviceSelector == "VideoInput") return this.videoDevice;
      if (this.deviceSelector == "AudioInput") return this.audioDevice;
      if (this.deviceSelector == "AudioOutput") return this.speakerDevice;
      return null;
    },

    toogleDevice() {
      if (this.deviceSelector == "VideoInput") return this.camEnabled;
      if (this.deviceSelector == "AudioInput") return this.audioEnabled;
      if (this.deviceSelector == "AudioOutput") return this.speakerEnabled;
      return false;
    },

    timeDay() {
      return moment().format("dddd, MMMM D YYYY");
    },

    UsableHeight() {
      return window.innerHeight + "px";
    },

    isIOS() {
      return EvalMobile.iOS();
    },

    isSharedVideo() {
      return !!this.videosIds.find(vid => vid.isContent);
    }
  },

  methods: {
    ...mapMutations({
      toogleCamModal: "meeting/toogleCamModal"
    }),
    ...mapActions({
      setMeetType: "meeting/setMeetType",
      startCamera: "meeting/startCamera",
      setAtendeeName: "meeting/setAtendeeName",
      create: "meeting/startMeet",
      endMeet: "meeting/endMeet",
      changeVideoDevice: "meeting/changeVideoDevice",
      sendMessage: "meeting/sendMessage",
      changeSpeakerDevice: "meeting/changeSpeakerDevice",
      changeAudioDevice: "meeting/changeAudioDevice",
      // toogles
      toogleSharingEnabled: "meeting/toogleSharingEnabled",
      toogleCamEnabled: "meeting/toogleCamEnabled",
      toogleSpeakerEnabled: "meeting/toogleSpeakerEnabled",
      toogleAudioEnabled: "meeting/toogleAudioEnabled",
      sendFile: "meeting/sendFile",
      sendTracking: "virtualrow/handleTracking"
    }),

    debugModeCounter() {
      ++this.debugCounter;
      if (this.timerDebugMode) clearTimeout(this.timerDebugMode);
      if (this.debugCounter >= 5) {
        this.debugMode = !this.debugMode;
        this.debugCounter = 0;
      }
      this.timerDebugMode = setTimeout(() => {
        this.debugCounter = 0;
      }, 500);
    },

    showMsgBoxTwo(status) {
      this.$bvModal.msgBoxOk(
        status
          ? "Se ha restablecido la conexión de video."
          : "Se desconecta el video por problemas de red.",
        {
          title: "Evento de video",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true
        }
      );
    },

    async startup() {
      this.setMeetType(true); // this.meetType === "agent" // TODO: Marcelo y Karen piden esto.
      try {
        this.setAtendeeName(this.attendanceName);
        await this.create({
          meetId: this.meetIdentity,
          username: this.attendanceName,
          turnCode: this.turn.code
        });
        this.stage = 1;
        // this.agentController(); // TODO: enable it in demo to clients. Show modal if agent not connect to meet
      } catch (error) {
        this.stage = 2;
        console.error("Stop meeting because a error", error);
        this.$rollbar.warning("Stop meeting because a error", { error });
        this.$emit("endMeet");
        this.endMeet();
      }
    },

    startCameraHandler() {
      this.startCamera();
      setTimeout(() => {
        this.toogleCamModal();
      }, 1000);
    },

    async enableCamera() {
      await this.startCamera();
    },

    messageHandler() {
      this.sendMessage({ message: this.message });
      this.message = "";
      this.showSendIcon = false;
    },

    async end() {
      await this.endMeet();
    },

    timeFormat(time) {
      return moment(time).format("hh:mm a");
    },

    agentController(canceled = false) {
      if (canceled == true) {
        clearTimeout(this.timerWatcher);
        this.timerWatcher = null;
        return;
      }
      this.timerWatcher = setTimeout(() => {
        this.$bvModal.show("Agent-not-connect");
      }, this.waiting_time * 1000);
    },

    fileHandlerImage() {
      this.fileHandler(this.$refs.image);
    },

    fileHandlerGeneral() {
      this.fileHandler(this.$refs.file);
    },

    toogleInvalidModal() {
      this.$bvModal.hide("invalid-mime");
      this.$bvModal.hide("invalid-size");
    },

    async fileHandler(reference) {
      const files = reference.files;
      if (!files[0]) return;
      const infoFile = {
        name: files[0].name,
        ext: files[0].name.split(".")[files[0].name.split(".").length - 1],
        mime: files[0].type,
        size: files[0].size
      };
      const isValidMime = this.validMimeTypes.reduce(
        (prev, curr) => infoFile.mime.indexOf(curr) === 0 || prev,
        false
      );
      const isValidSize = infoFile.size < 10000000;
      if (!isValidMime) {
        this.$bvModal.show("invalid-mime");
        return;
      }
      if (!isValidSize) {
        this.$bvModal.show("invalid-size");
        return;
      }
      this.sendMessage({
        message: `
          <div class="position-relative text-truncate">
            <i style="font-size: 0.7rem;">Estamos cargando tu archivo ${files[0].name}</i>
          </div>
        `,
        local: true
      });

      try {
        const { url } = await this.fileHandlerSender(files[0]);
        const template = `
            <div class="position-relative">
              <div class="folderIcon text-truncate"><a style="color: white;" href="${url}" download="${files[0].name}" target="_blank">${files[0].name}</a></div>
            </div>
          `;
        this.sendMessage({
          message: `
            <div class="position-relative text-truncate">
              <i style="font-size: 0.7rem; color: #dddddd;">Tu archivo ${files[0].name} se envió correctamente.</i>
            </div>
          `,
          local: true
        });
        this.sendMessage({ message: template });
      } catch (error) {
        this.sendMessage({
          message: `
            <div class="position-relative text-truncate">
              <i style="font-size: 0.7rem; color: brown;">Tu archivo ${files[0].name} no pudo ser cargado. Inténtalo de nuevo.</i>
            </div>
          `,
          local: true
        });
        window.RB.warning("Invalid file to charge", { infoFile });
      }
      reference.value = "";
    },

    async simulateAttach() {
      this.simulateClick(this.$refs.file);
    },

    async simulateImage() {
      this.simulateClick(this.$refs.image);
    },

    async fileHandlerSender(file) {
      const result = {
        turn_code: this.meetIdentity,
        call_id: this.meetIdentity, // FIXME: Code ... check current meeting id
        name: file.name,
        type: file.type,
        b64: file
      };

      try {

        return await this.sendFile(result);

      } catch (error) {

        // tracking
        let font = _get(this.c_data, 'fu', '');
        font = !font || font == '' ? 'ad' : font;
        let trackingData = {
          uuid: window.person.id,
          accion: "error-send-file-chat",
          debmedia_turn_code: this.turn.code,
          url_origen: window.location.href,
          origen: font,
          mensajes: error + ' ' + JSON.stringify(result)
        };

        this.sendTracking(trackingData);

      }
    },
    readed(file) {
      return new Promise((res, rej) => {
        var reader = new FileReader();
        reader.onload = () => res(reader.result);
        reader.onerror = err => rej(err);
        reader.readAsDataURL(file);
      });
    },

    simulateClick(elem) {
      // Create our event (with options)
      var evt = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window
      });
      // If cancelled, don't dispatch our event
      elem.dispatchEvent(evt);
    },

    selectedDeviceInput(devices) {
      this.deviceSelector = devices;
      this.$bvModal.show("Input-selector");
    },

    changeDeviceSelected(device) {
      if (this.deviceSelector == "VideoInput") this.changeVideoDevice(device);
      if (this.deviceSelector == "AudioInput") this.changeAudioDevice(device);
      if (this.deviceSelector == "AudioOutput")
        this.changeSpeakerDevice(device);
      this.closeModalDevices();
    },

    disableDevice() {
      if (this.deviceSelector == "VideoInput") this.toogleCamEnabled();
      if (this.deviceSelector == "AudioInput") this.toogleAudioEnabled();
      if (this.deviceSelector == "AudioOutput") this.toogleSpeakerEnabled();
      this.closeModalDevices();
    },

    startCamFromBasis() {
      if (!this.videoDevice) this.toogleCamModal();
      else this.toogleCamEnabled();
    },

    closeModalDevices() {
      // close modal and leave selector at null
      setTimeout(() => {
        this.$bvModal.hide("Input-selector");
      }, 600);
    },

    handleChangeMessage() {
      this.showSendIcon = true;
    },

    modeScroll(messageNumber) {
      var myMsg = document.getElementById(`message_${messageNumber - 1}`);
      if (myMsg) {
        var topPos = myMsg.offsetTop;
        document.getElementById("full_messages").scrollTop = topPos;
      }
    }
  }
};
</script>

<style scoped>
.imageConnection {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.size_icon {
  width: 30%;
  max-width: 200px;
  text-align: center;
}
.videoStopSuggestionMessage {
  position: absolute;
  bottom: -5px;
  text-align: center;
  width: 100%;
  font-size: smaller;
  z-index: 3;
}
.container_meet {
  overflow-x: auto;
}
.header {
  height: 6%;
}
.videozone {
  height: 34%;
  max-width: 900px;
  margin: auto;
}
.chatzone {
  height: calc(60% - 30px);
}

.containerMsg {
  padding: 8px;
  padding-top: 10px;
  height: calc(100% - 4rem);
  position: relative;
}

.messagesZone {
  display: flex;
  height: calc(100% - 56px - 30px);
  overflow: auto;
  flex-direction: column;
  position: relative;
}

.fullChat {
  background-color: #e9ebf2;
  border-radius: 10px;
  height: 100%;
  max-width: 950px;
  margin: auto;
}

.dateTittle {
  text-align: center;
  margin: 0px 5px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6c757d;
  border-bottom: 1px solid #6c757d;
}

.img_cont_msg {
  height: 40px;
  width: 40px;
}
.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}
.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 5px; /* 25px; */
  position: relative;
  max-width: 100%;
}

.msg_text {
  padding: 5px 10px;
  border-radius: 5px;
  overflow: auto;
}

.msg_owner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #6d7074;
}
.msg_owner_client {
  text-align: right;
}
.msg_owner_agent {
  text-align: left;
}

.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 5px; /* 25px; */
  background-color: #c3d9c8; /* #78e08f; */
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
}
.msg_time {
  left: 0;
  bottom: -15px;
  color: darkgray;
  font-size: 10px;
}
.msg_time_send {
  text-align: right;
  right: 0;
  bottom: -15px;
  color: darkgray;
  font-size: 10px;
  margin: 0px 10px;
  display: block;
}
.attach_btn {
  /* border-radius: 5px 0 0 5px !important; 15px 0 0 15px !important; */
  background-color: rgba(0, 0, 0, 0.1) !important;
  /* border: 0 !important; */
  color: #6c757d !important;
  cursor: pointer;
  height: auto;
  display: block;
}
.send_btn {
  border-radius: 0 5px 5px 0 !important; /* 0 15px 15px 0 !important; */
  background-color: rgba(0, 0, 0, 0.1) !important;
  border: 0 !important;
  color: #6c757d !important;
  height: auto;
}
.type_msg {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border: 0 !important;
  color: #6c757d !important;
  height: 40px !important;
  overflow-y: auto;
}
.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.bottomzone {
  /* position: absolute;
  bottom: 0;
  width: 100%; */
  margin-top: -50px;
}
.chatWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .chatWrapper {
    min-height: 400px;
  }
}

.size-buttons {
  height: 80%;
  margin: 0px auto;
  position: relative;
  display: block;
}

/* tootle */
.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-toogle {
  height: 40px;
}

.fontSizeButton {
  font-size: 14px;
}

.version-position {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 8px;
  color: #6c757d;
}

.justify-content-start {
  max-width: 60%;
}

.justify-content-end {
  display: flex;
  align-self: flex-end;
  -webkit-align-self: flex-end;
}

.green-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #2cb9b0;
  margin-right: 5px;
}

.red-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #b92c2c;
  margin-right: 5px;
}

.lds-ellipsis {
  display: flex;
  position: relative;
  width: 50px;
  align-items: center;
  height: 30px;
}
.lds-ellipsis div {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 5px;
  animation: lds-ellipsis1 0.8s infinite ease-in-out;
}
.lds-ellipsis div:nth-child(2) {
  left: 5px;
  animation: lds-ellipsis2 0.8s infinite ease-in-out alternate;
}
.lds-ellipsis div:nth-child(3) {
  left: 17px;
  animation: lds-ellipsis2 0.8s infinite ease-in-out alternate;
}
.lds-ellipsis div:nth-child(4) {
  left: 33px;
  animation: lds-ellipsis3 0.8s infinite ease-in-out alternate;
}
.typing {
  border-radius: 10px;
  background-color: #2e303e;
  width: 42px;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  align-self: flex-end;
  -webkit-align-self: flex-end;
  position: relative;
}
.typing-2 {
  border-radius: 10px;
  background-color: #54678f;
  width: 42px;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  position: relative;
}

.triangulo {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid #212624;
  border-top: 19px solid #0000;
  border-bottom: 0px solid #0000;
  /* top: 0; */
  bottom: 0px;
  right: -5px;
}

.triangulo-chat {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid #2e303e;
  border-top: 19px solid #0000;
  border-bottom: 0px solid #0000;
  /* top: 0; */
  bottom: 3px;
  right: -3px;
}

.triangulo_noself {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 11px solid #54678f;
  border-top: 0px solid #0000;
  border-bottom: 15px solid #0000;
  left: -5px;
  top: 16px;
  transform: rotate(90deg);
}

.triangulo_noself-chat {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid #54678f;
  border-top: 0px solid #0000;
  border-bottom: 14px solid #0000;
  left: -2px;
  bottom: 15px;
  transform: rotate(90deg);
}

.pharragraph_message {
  width: 50%;
  min-width: 80px;
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(15px, 0);
  }
}
</style>
